import { type User } from 'next-auth';

import { type DateRangeLabel } from 'components/Filters/dateRangeOptions';
import { type StepId } from 'features/intake/hooks/useIntakeSteps';
import { type NotificationType } from 'features/notifications/types';
import { type ReasonKeys } from 'hooks/usePicklists';
import { type BookingStatus } from 'types/Booking';
import { type INCIDENT_TYPES } from 'types/Incident';
import { type ListingStatus } from 'types/Listing';
import {
  type OCStep,
  type OCReason,
  type OCSubreason,
  type OCScenario,
} from 'types/OwnerCancellation';
import { type RequestStatus } from 'types/Requests';
import { type FileType } from 'types/file';
import { type WhatsNewModalFeatures } from 'types/segment';

import { type CreatePropertyIntakeFields } from './__generated/graphql/graphql';
import { TitleCase } from './strings/case';

const routeTitles: Record<string, string> = {
  '/': 'Dashboard',
  '/bookings': 'Bookings',
  '/reports': 'Reports',
  '/listings': 'Listings',
  '/account': 'Account',
  '/requests': 'Requests',
  '/performance': 'Performance',
  '/referrals': 'Referrals',
  '/faq': 'FAQ',
  '/start': 'RFI',
};

// for source in navigation events
export function getRouteTitle(route: string): string {
  return routeTitles[route] || route;
}

interface SegmentEventCommonProperties {
  browser_version: string;
  browser: string;
  device_form_factor: 'mobile' | 'tablet' | 'desktop' | 'unknown';
  device_os_version: string;
  device_os: string;
  platform: 'web';
  view_height: number;
  view_scale: number;
  view_width: number;
}

export const getCommonProperties =
  async (): Promise<SegmentEventCommonProperties> => {
    const {
      browserName,
      fullBrowserVersion,
      isDesktop,
      isMobile,
      isTablet,
      osName,
      osVersion,
    } = await import('react-device-detect');

    return {
      browser_version: fullBrowserVersion,
      browser: browserName,
      device_form_factor: isMobile
        ? 'mobile'
        : isTablet
          ? 'tablet'
          : isDesktop
            ? 'desktop'
            : 'unknown',
      device_os_version: osVersion,
      device_os: osName,
      platform: 'web',
      view_height: window.innerHeight,
      view_scale: window.devicePixelRatio,
      view_width: window.innerWidth,
    };
  };

interface IdentifyEventTraits extends SegmentEventCommonProperties {
  account_id: string;
  client_id: string;
  contact_id: string;
  email: string;
  management_plan?: string;
  user_id: string;
}

export const identifyUser = async (
  userProfile: NonNullable<User['profile']>
) => {
  try {
    const commonProperties = await getCommonProperties();

    const traits: IdentifyEventTraits = {
      user_id: userProfile.userId,
      account_id: userProfile.accountId,
      client_id: userProfile.clientId,
      contact_id: userProfile.contactId,
      email: userProfile.email ?? '',
      management_plan: userProfile.managementTier,
      ...commonProperties,
    };

    window.analytics.identify(userProfile.userId, traits);
  } catch (error) {
    console.error(`Error identifying user: ${error}`);
  }
};

export interface SegmentTrackEventProperties {
  // ************ Account ************
  'Account Edit Info Clicked': {
    primary_billing_contact: 'yes' | 'no';
    field_name:
      | 'Name'
      | 'Address'
      | 'Password'
      | 'Bank Account'
      | 'Credit Card';
  };
  'Account Edit Info Save Clicked': {
    primary_billing_contact: 'yes' | 'no';
    field_name:
      | 'Name'
      | 'Address'
      | 'Password'
      | 'Bank Account'
      | 'Credit Card';
  };
  'Account Edit Info Save Complete': {
    primary_billing_contact: 'yes' | 'no';
    field_name:
      | 'Name'
      | 'Address'
      | 'Password'
      | 'Bank Account'
      | 'Credit Card';
  };
  'Account Edit Info Save Failed': {
    primary_billing_contact: 'yes' | 'no';
    field_name:
      | 'Name'
      | 'Address'
      | 'Password'
      | 'Bank Account'
      | 'Credit Card';
    error_message: string;
  };
  'Account Tip Clicked': {
    primary_billing_contact: 'yes' | 'no';
    tip_name: 'Email' | 'Phone';
  };
  'Account External Link Clicked': {
    primary_billing_contact: 'yes' | 'no';
    link_name:
      | 'Plus Benefits'
      | 'Plans Learn More'
      | 'Rental Policy'
      | 'Management Agreement';
    link_url: string;
  };
  // ************ Dashboard ************
  'Dashboard Upcoming Bookings Listing Selector Clicked': {
    num_listings: number;
  };
  'Dashboard Upcoming Bookings Listing Selected': {
    listings_selected: 'all' | 'single';
  };
  'Dashboard Stats Timeframe Changed': {
    timeframe: 'All' | 'YTD' | 'MTD';
  };
  'Dashboard Link Clicked': {
    destination: 'Calendar' | 'Performance' | 'Listings' | 'Listing Overview';
  };
  'Dashboard Upcoming Bookings Booking Clicked': undefined;

  // ************ Login ************
  'Login Button': {
    email: string;
    impersonate_user?: string;
  };
  'Learn More Clicked': undefined;
  'Login Failed': {
    email: string;
    reason: string;
  };
  'Login Complete': {
    email: string;
    impersonate_user?: string;
  };
  'Back To Login Clicked': undefined;
  'Reset Password Email Clicked': {
    email: string;
  };
  'Reset Password Complete': {
    email: string | null;
  };
  'Resend Email Clicked': {
    resend_attempts: number;
  };
  'Update Password Clicked': undefined;
  // ************ Bookings ************
  'Bookings Report Guest Name Clicked': {
    booking_report_page_depth: number;
  };
  'Bookings Report Page Changed': {
    booking_report_page_depth: number;
  };
  'Bookings Report': {
    num_bookings: number | undefined;
  };
  'Bookings Report Download Clicked': {
    fileType: FileType;
  };
  'Bookings Calendar Date Load': {
    view: string;
    action: 'Today' | 'Next' | 'Previous' | 'Initial Load';
    date_start: string;
    date_end: string;
    date_month?: string;
    listings_selected: number;
  };
  'Bookings Calendar Listing Selected': {
    listings_count: number;
    listings_selected: number;
  };
  'Bookings Calendar Popover Viewed': undefined;
  'Guest Booking Contact Info Clicked': {
    contact_type: 'Email' | 'Phone';
  };
  'Guest Booking Details Submit a Request Button Clicked': undefined;
  'Navigation Bookings Clicked': {
    source: string;
  };
  'Navigation Feedback Clicked': {
    source: string;
  };
  'Navigation Listings Clicked': {
    source: string;
  };
  'Navigation Reimbursements Clicked': {
    source: string;
  };
  'Navigation Reports Clicked': {
    source: string;
  };
  'Navigation Requests Clicked': {
    source: string;
  };
  'Navigation Account Clicked': {
    source: string;
  };
  'Navigation Chat Clicked': {
    source: string;
  };
  'Navigation FAQ Clicked': {
    source: string;
  };
  'Navigation Referrals Clicked': {
    source: string;
  };
  'Navigation Performance Clicked': {
    source: string;
  };
  'Feedback Share Feedback Clicked': {
    source: string;
  };
  'Feedback Submit a Request Clicked': {
    source: string;
  };
  'New Dialog': {
    feature: WhatsNewModalFeatures;
  };
  'New Dialog CTA Clicked': {
    feature: WhatsNewModalFeatures;
  };
  'New Dialog Dismissed': {
    feature: WhatsNewModalFeatures;
  };
  'Account Logout Clicked': {
    source: string;
  };
  'Owner Block Creation': {
    source: 'Calendar Button' | 'Calendar Date' | 'Listing Overview';
  };
  'Owner Block Created': {
    source: 'Calendar Button' | 'Listing Details' | 'Calendar Date';
    reason: string;
    reason_sub: string;
    revenue_missed: number;
    blocked_days: number;
    start_date: string;
    end_date: string;
  };
  'Owner Block Details': {
    source: string;
    reason: string;
    reason_sub: string;
    blocked_days: number;
  };
  'Guest Booking Details': {
    source: string;
    status: BookingStatus;
    distribution_site: string;
    cancel_fee: 'None' | 'Generated' | 'Applied' | 'AppliedRefund';
    cancel_fee_amount?: number;
    self_cancel_status: 'Available' | 'Pending' | 'Complete' | 'Unavailable';
  };
  'Owner Block Cancelled': {
    source: string;
    reason: string;
    reason_sub: string;
    blocked_days: number;
    start_date: string;
    end_date: string;
  };
  'Owner Block Modify': {
    source: string;
  };
  'Owner Block Modified': {
    source: string;
    reason: string;
    reason_sub: string;
    revenue_missed: number;
    blocked_days: number;
    start_date: string;
    end_date: string;
  };
  'Cancel Booking Back Clicked': {
    booking_id: string;
    page_source: OCStep;
  };
  'Cancel Booking Internal Link Clicked':
    | {
        booking_id: string;
        destination: 'SubmitRequest' | 'ReportIncident' | 'Reimbursements';
        page_source: 'Info';
        category?: never;
        subreason?: never;
        scenario?: never;
      }
    | {
        booking_id: string;
        destination: 'SubmitRequest' | 'ReportIncident' | 'Reimbursements';
        page_source: Exclude<OCStep, 'Info'>;
        category: OCReason['id'];
        subreason: OCSubreason['id'];
        scenario: OCScenario;
      };
  'Cancel Booking External Link Clicked':
    | {
        booking_id: string;
        /** Full URL of the external link clicked */
        destination: `https://${string}`;
        page_source: 'Info';
        category?: never;
        subreason?: never;
        scenario?: never;
      }
    | {
        booking_id: string;
        /** Full URL of the external link clicked */
        destination: `https://${string}`;
        page_source: Exclude<OCStep, 'Info'>;
        category: OCReason['id'];
        subreason: OCSubreason['id'];
        scenario: OCScenario;
      };
  'Cancel Booking Tip Clicked': {
    booking_id: string;
    page_source: OCStep;
    tip_name: 'VrboWarning' | 'CancelFee' | 'OfferDiscount' | 'NoInvoice';
  };
  'Cancel Booking Keep Booking Clicked': {
    booking_id: string;
    page_source: OCStep;
  };
  'Cancel Booking Discard Confirmed': {
    booking_id: string;
    page_source: OCStep;
  };
  'Cancel Booking Info Page Next Clicked': {
    booking_id: string;
  };
  'Cancel Booking Reasons Page Next Clicked': {
    booking_id: string;
    category: OCReason['id'];
    subreason: OCSubreason['id'];
    scenario: OCScenario;
  };
  'Cancel Booking Recap Page Next Clicked': {
    booking_id: string;
    category: OCReason['id'];
    subreason: OCSubreason['id'];
    scenario: OCScenario;
    num_files: number;
    recap_chars: number;
    days_until_ready: number;
  };
  'Cancel Booking Fee Page Next Clicked': {
    booking_id: string;
    category: OCReason['id'];
    subreason: OCSubreason['id'];
    scenario: OCScenario;
    fee_amount: number;
  };
  'Cancel Booking Form Submit Complete': {
    booking_id: string;
    category: OCReason['id'];
    subreason: OCSubreason['id'];
    scenario: OCScenario;
    fee_amount: number;
  };
  'Cancel Booking Form Submit Failed': {
    booking_id: string;
    category: OCReason['id'];
    subreason: OCSubreason['id'];
    scenario: OCScenario;
    fee_amount: number;
    error: 'RequestNotSubmitted' | 'DocsNotUploaded';
  };
  // ************ Reports ************
  'Reports Completed Payouts': {
    num_payouts: number;
  };
  'Reports Completed Payouts Booking Details Clicked': Record<string, never>;
  'Reports Completed Payouts Date Tool Tip Clicked': Record<string, never>;
  'Reports Completed Payouts Booking Modified Tool Tip Clicked': Record<
    string,
    never
  >;
  'Reports Completed Payouts Filtered': Record<string, never>;
  'Reports Completed Payouts Pagination Clicked': {
    reports_record_count: number;
    reports_page_depth: number;
  };
  'Reports Taxes Booking Modified Tool Tip Clicked': Record<string, never>;
  'Reports Download Clicked': {
    report_category: 'Taxes' | 'Completed Payouts' | 'Pending Payouts';
  };
  'Reports Download Guide Clicked': {
    report_category: 'Taxes' | 'Completed Payouts' | 'Pending Payouts';
  };
  'Reports Download Complete': {
    report_category: 'Taxes' | 'Completed Payouts' | 'Pending Payouts';
    file_type: 'csv' | 'pdf';
    filter_listing_count: number;
    filter_distributor_count: number;
    filter_date: string;
    report_name:
      | 'Guest-paid Taxes'
      | 'Income'
      | 'Taxes (Old)'
      | 'Taxes Report'
      | 'Completed Payouts'
      | 'Pending Payouts'
      | 'Completed Payouts (Old)'
      | 'Pending Payouts (Old)';
  };
  'Reports Pending Payouts Booking Details Clicked': Record<string, never>;
  'Reports Pending Payouts Date Tool Tip Clicked': Record<string, never>;
  'Reports Pending Payouts': {
    num_payouts: number;
  };
  'Reports Pending Payouts Filtered': Record<string, never>;
  'Reports Pending Payouts Pagination Clicked': {
    reports_record_count: number;
    reports_page_depth: number;
  };
  'Reports Pending Payouts Booking Modified Tool Tip Clicked': Record<
    string,
    never
  >;
  'Reports Charges': {
    num_payouts: number;
  };
  'Reports Charges Filtered': Record<string, never>;
  'Reports Charges Booking Details Clicked': Record<string, never>;
  'Reports Charges Date Tool Tip Clicked': Record<string, never>;
  'Reports Charges Pagination Clicked': {
    reports_record_count: number;
    reports_page_depth: number;
  };
  'Reports Taxes': {
    num_payouts: number;
  };
  'Reports Taxes Filtered': Record<string, never>;
  'Reports Taxes Booking Details Clicked': Record<string, never>;
  'Reports Taxes Pagination Clicked': {
    reports_record_count: number;
    reports_page_depth: number;
  };
  'Reports Taxes View Tax Rates Clicked': Record<string, never>;
  'Reports Charges Payouts Pagination Clicked': {
    reports_record_count: number;
    reports_page_depth: number;
  };
  'Reports Credits': {
    num_credits: number;
  };
  'Reports Credits Details Clicked': {
    type: 'Credit' | 'Fee';
  };
  'Reports Credits Filtered': {
    filters_date?: DateRangeLabel;
    filters_date_start?: string;
    filters_date_end?: string;
    filters_type: 'All' | 'Credits' | 'Fees';
  };
  'Reports Credits Booking Details Clicked': {
    type: 'Credit' | 'Fee';
  };
  'Reports Credits Total Remaining Tool Tip Clicked': Record<string, never>;
  'Reports Credits Pagination Clicked': {
    record_count: number;
    page_depth: number;
  };
  'Reports Credits Modal Expanded': Record<string, never>;

  // ************ Performance ************
  'Performance Metric Loaded': {
    metric: 'Payouts';
    visualization_type: 'Graph' | 'Table';
    listing: 'All' | string;
    year: number;
    num_listings: number;
    num_years: number;
    empty_state: boolean;
  };
  'Performance Tooltip Viewed': {
    tip_name: 'PayoutsCalculation' | 'PayoutsCompleted' | 'PayoutsPending';
  };
  'Performance Recommendations Loaded': {
    new_listing: boolean;
    high_min_rate: boolean;
  };
  'Performance Recommendations CTA Clicked': {
    new_listing: boolean;
    high_min_rate: boolean;
    // See "Article Name (for Segment)" column at https://docs.google.com/spreadsheets/d/1CdOJyGZ_GFL6t3u66gUAM_Y40eqHGVG2LhICU8SJYHc/edit#gid=2034165628
    article_name: '';
  };
  // ************ Listings ************
  'Listings List Reviews Clicked': {
    listing_status?: ListingStatus;
    total_reviews: number;
    average_review_rating: number;
  };
  'Listings List Listing Details Clicked': {
    listing_status?: ListingStatus;
  };
  'Listings List Complete Listing Clicked': undefined;
  'Listings List Review Listing Clicked': undefined;
  'Listings List Pagination Clicked': {
    record_count: number;
    page_depth: number;
  };
  'Listings List Add New Listing Clicked': { listings_count: number };
  'Listings List Add New Listing Submit': {
    listings_count: number;
    bedrooms: number;
    full_bathrooms: number;
    half_bathrooms: number;
  };
  'Listings List Add New Listing Complete': {
    listings_count: number;
    bedrooms: number;
    full_bathrooms: number;
    half_bathrooms: number;
  };
  'Listing Details Overview Clicked': {
    listing_status?: ListingStatus;
  };
  'Listing Details External Listing Clicked': {
    distro_site: string;
  };
  'Listing Details Description Clicked': {
    listing_status?: ListingStatus;
  };
  'Listing Details Pre-stay Info Clicked': {
    listing_status?: ListingStatus;
  };
  'Listing Details Contact Info Clicked': {
    contact_type: 'Email' | 'Phone';
  };
  'Listing Details View All Photos Clicked': undefined;
  'Listing Details All Photos Exited': undefined;
  'Listing Details Add Owner Block Clicked': undefined;
  'Listing Details Submit Request Clicked': undefined;
  'Listing Details Reviews List Filtered': {
    reviews_count: number;
    filters_rating: string | null;
    filters_check_in_date: DateRangeLabel;
    filters_source_count: number | null;
    filters_source: string;
  };
  'Listing Details Reviews Pagination Clicked': {
    record_count: number;
    page_depth: number;
  };
  'Listing Details Reviews Clicked': {
    listing_status?: string;
  };
  'Listing Details Review Details Clicked': {
    review_rating: number;
    review_source: string;
  };
  'Listing Details Review Comment Submitted': {
    review_rating: number;
    review_source: string;
    comment_count: number;
  };
  'Listing Details Edit Property Name Clicked': {
    listing_status?: string;
    old_name: string | null;
  };
  'Listing Details Edit Property Name Save Clicked': {
    old_name: string | null;
    new_name: string | null;
  };
  'Listing Details Edit Property Name Complete': {
    listing_status?: string;
    new_name: string | null;
  };
  // ************ Reimbursements ************
  'Reimbursement List Reimbursement ID Clicked': {
    reimbursement_page_depth: number;
  };
  'Reimbursement Details Incident Report Clicked': {
    reimbursement_status: RequestStatus;
  };
  'Reimbursement Details Booking Clicked': {
    reimbursement_status: RequestStatus;
  };
  'Reimbursement Details Listing Clicked': {
    reimbursement_status: RequestStatus;
  };
  'Reimbursement Details Withdraw Clicked': {
    reimbursement_status: RequestStatus;
    amount_requested: number;
  };
  'Reimbursement Details Withdraw Confirmed': {
    reimbursement_status: RequestStatus;
    amount_requested: number;
  };
  'Reimbursement Details Approved': {
    amount_requested: number;
    amount_reimbursed: number;
  };
  'Reimbursement Details Contact Evolve Clicked': {
    amount_requested: number;
    amount_reimbursed: number;
  };
  'Reimbursement Details Contact Evolve Sent': {
    amount_requested: number;
    amount_reimbursed: number;
  };
  'Reimbursement Details Edit Items Clicked': {
    reimbursement_status: RequestStatus;
    amount_requested: number;
    reimbursement_items_count: number;
  };
  'Reimbursement Details Delete Item Clicked': {
    reimbursement_status: RequestStatus;
    amount_requested: number;
    reimbursement_items_count: number;
  };
  'Reimbursement Details Items Saved': {
    reimbursement_status: RequestStatus;
    amount_requested: number;
    items_added: number;
    items_removed: number;
    reimbursement_items_count: number;
  };
  'Reimbursement Details Exit Modal Clicked': {
    reimbursement_status: RequestStatus;
    amount_requested: number;
    reimbursement_items_count: number;
  };
  'Reimbursement Details Discard Changes Confirmed': {
    reimbursement_status: RequestStatus;
    amount_requested: number;
    reimbursement_items_count: number;
  };
  // ************ Referrals ************
  'Referrals Copy URL Clicked': {
    referrals_total_count: number;
    referrals_pending_count: number;
    referrals_complete_count: number;
    referrals_dollars: number;
  };
  'Referrals Share Button Clicked': {
    referrals_total_count: number;
    referrals_pending_count: number;
    referrals_complete_count: number;
    referrals_dollars: number;
    share_channel: 'Email' | 'Facebook' | 'X' | 'LinkedIn';
  };
  'Referrals External Link Clicked': {
    referrals_total_count: number;
    referrals_pending_count: number;
    referrals_complete_count: number;
    referrals_dollars: number;
    link_url: string;
  };
  // ************ Report an Incident ************
  'Reimbursements List Report an Incident Clicked': Record<string, never>;
  'Guest Booking Details Report an Incident Clicked': Record<string, never>;
  'Guest Booking Cancellation Tool Tip Clicked': undefined;
  'Guest Booking Adjustments Clicked': undefined;
  'Report an Incident Add Item Clicked': {
    items_count: number;
    total_cost: number;
  };
  'Report an Incident Attach Files Clicked': {
    files_count: number;
    method: 'DragAndDrop' | 'FileSelector';
  };
  'Report an Incident Remove File Clicked': {
    files_count: number;
  };
  'Report an Incident Submit Clicked': {
    booking_id: string;
    incident_type: TitleCase<(typeof INCIDENT_TYPES)[number]>;
    items_count: number;
    total_cost: number;
    files_count: number;
  };
  'Report an Incident Submit Completed': {
    booking_id: string;
    incident_type: TitleCase<(typeof INCIDENT_TYPES)[number]>;
    items_count: number;
    total_cost: number;
    files_count: number;
  };
  'Reimbursements List Filtered': {
    filters_status: RequestStatus[];
    reimbursements_count: number;
  };
  'Reimbursement List Pagination Clicked': {
    record_count: number;
    page_depth: number;
  };
  // ************ Requests ************
  'Requests List Filtered': {
    requests_status_filter: string;
    requests_count: number;
  };
  'Requests List Pagination Clicked': {
    record_count: number;
    page_depth: number;
  };

  'Request Details Comment Submited': {
    request_type: string;
    request_status: string;
    request_comments: number;
  };
  'Request Details Comments Pagination Clicked': {
    record_count: number;
    page_depth: number;
  };
  'Request Details Upload File Clicked': {
    request_status: string;
    request_files: number;
    method: string;
  };
  'Request Details Upload File Complete': {
    request_status: string;
    total_files: number;
    files_added: number;
  };
  'Request Details Delete File Clicked': {
    request_status: string;
    request_files: number;
  };
  'Request Details Delete Dialogue': undefined;
  'Request Details Delete Dialogue Dismissed': undefined;
  'Request Details File Deleted': {
    request_status: string;
    request_files: number;
  };
  'Listing Details Fees & Rules Clicked': {
    listing_status?: ListingStatus;
  };
  'Listing Details Edit Cleaning Fee Clicked': {
    fee_amount: number;
  };
  'Listing Details Edit Cleaning Fee Save Clicked': {
    fee_amount: number;
  };
  'Listing Details Edit Cleaning Fee Complete': {
    fee_amount: number;
  };
  'Listing Details Amenities Clicked': {
    listing_status?: ListingStatus;
  };
  'Listing Details Update Amenities Clicked': {
    listing_status?: ListingStatus;
    amenities_count: number;
  };
  'Listing Details Update Amenities Save Clicked': {
    amenities_added: number;
    amenities_removed: number;
    amenities_count: number;
  };
  'Listing Details Update Amenities Complete': {
    amenities_added: number;
    amenities_removed: number;
    amenities_count: number;
  };
  // ************ Notifications ************
  'Notification List': {
    total_notifications: number;
    unread_notifications: number;
    read_notifications: number;
  };
  'Notification List Mark Read Clicked': {
    marked_notifications: number;
  };
  'Notification Clicked': {
    notification_type: NotificationType;
    click_source: 'Push' | 'In-App';
  };
  // ************ Requests ************
  'Requests List Submit a Request Button Clicked': undefined;
  'Submit Request Form Redirect CTA Clicked': { request_reason: ReasonKeys };
  'Submit Request Form Closed': undefined;
  'Submit Request Form Submit Clicked': {
    listing_id: string;
    request_reason: ReasonKeys;
  };
  'Submit Request Form Completed': {
    listing_id: string;
    request_reason: ReasonKeys;
  };
  // ************ Licenses ************
  'Listing Details Licenses Clicked': {
    listing_status?: ListingStatus;
  };
  'Listing Details Licenses Pagination Clicked': {
    record_count: number;
    page_depth: number;
  };
  'Listing Details Add License Clicked': {
    license_count: number;
  };
  'Listing Details Edit License Clicked': {
    license_count: number;
  };
  'Listing Details Add License Cancel Clicked': {
    license_count: number;
  };
  'Listing Details Add License Attach Files Clicked': {
    files_count: number; // Number files before new one attached
  };
  'Listing Details Add License Attach Files Complete': {
    files_count: number; // Number files after new one attached
  };
  'Listing Details Add License Remove Files Clicked': {
    files_count: number; // Number files after new one attached
  };
  'Listing Details Add License Save Clicked': {
    files_count: number;
  };
  'Listing Details Add License Complete': {
    license_count: number;
  };
  'Listing Details Edit License Save Clicked': {
    files_count: number;
  };
  'Listing Details Edit License Complete': {
    license_count: number;
  };
  // ************ Min Rate Editor ************
  'Listing Details Min Rate Editor Visible': {
    min_rate: number;
  };
  'Min Rate Editor Tooltip Clicked': {
    min_rate: number;
  };
  'Min Rate Editor External Link Clicked': {
    min_rate: number;
    article_name: 'RateStrategy' | 'MinRate';
  };
  'Min Rate Editor Save Clicked': {
    old_min_rate: number;
    new_min_rate: number;
    upper_rate: number | 'none';
    middle_rate: number | 'none';
    lower_rate: number | 'none';
    new_minus_old_rate: number | 'none';
    new_minus_upper_rate: number | 'none';
    new_minus_middle_rate: number | 'none';
    new_minus_lower_rate: number | 'none';
  };
  'Min Rate Editor Calculate Clicked': {
    old_min_rate: number;
    new_min_rate: number;
    upper_rate: number | 'none';
    middle_rate: number | 'none';
    lower_rate: number | 'none';
    new_minus_old_rate: number | 'none';
    new_minus_upper_rate: number | 'none';
    new_minus_middle_rate: number | 'none';
    new_minus_lower_rate: number | 'none';
  };
  'Min Rate Editor Predictions': {
    old_min_rate: number;
    new_min_rate: number;
    upper_rate: number | 'none';
    middle_rate: number | 'none';
    lower_rate: number | 'none';
    new_minus_old_rate: number | 'none';
    new_minus_upper_rate: number | 'none';
    new_minus_middle_rate: number | 'none';
    new_minus_lower_rate: number | 'none';
    old_revenue: number | 'none';
    new_revenue: number | 'none';
    new_minus_old_revenue: number | 'none';
    old_nights: number | 'none';
    new_nights: number | 'none';
    new_minus_old_nights: number | 'none';
    api_called: 'Yes' | 'No';
  };
  'Min Rate Editor Save Complete': {
    old_min_rate: number;
    new_min_rate: number;
    upper_rate: number | 'none';
    middle_rate: number | 'none';
    lower_rate: number | 'none';
    new_minus_old_rate: number | 'none';
    new_minus_upper_rate: number | 'none';
    new_minus_middle_rate: number | 'none';
    new_minus_lower_rate: number | 'none';
    old_revenue: number | 'none';
    new_revenue: number | 'none';
    new_minus_old_revenue: number | 'none';
    old_nights: number | 'none';
    new_nights: number | 'none';
    new_minus_old_nights: number | 'none';
    api_called: 'Yes' | 'No';
  };
  'Min Rate Editor Too High': {
    old_min_rate: number;
    new_min_rate: number;
    upper_rate: number | 'none';
    middle_rate: number | 'none';
    lower_rate: number | 'none';
    new_minus_old_rate: number | 'none';
    new_minus_upper_rate: number | 'none';
    new_minus_middle_rate: number | 'none';
    new_minus_lower_rate: number | 'none';
    message_shown: 'DedicatedAdvisor' | 'SubmitRequest';
  };
  'Min Rate Editor Too High Request Clicked': {
    old_min_rate: number;
    new_min_rate: number;
    upper_rate: number | 'none';
    middle_rate: number | 'none';
    lower_rate: number | 'none';
    new_minus_old_rate: number | 'none';
    new_minus_upper_rate: number | 'none';
    new_minus_middle_rate: number | 'none';
    new_minus_lower_rate: number | 'none';
  };
  'Min Rate Editor No Range': {
    min_rate: number;
  };
  'Min Rate Editor No Range Submit Clicked': {
    min_rate: number;
  };
  'Min Rate Editor Tip Expanded': {
    min_rate: number;
    tip_name: 'AMR' | 'RevenueImpact';
  };
  // ************ Custom Min Rates ************
  'Custom Rates Loaded': {
    status: 'Live' | 'Expired';
    num_rules_total: number;
  };
  'Custom Rates External Link Clicked': {
    article_name: 'HelpCenter';
  };
  'Custom Rates Add Rule Clicked': {
    min_rate: number;
  };
  'Custom Rates Add Rule Cancel Clicked': {
    min_rate: number;
  };
  'Custom Rates Add Rule Save Clicked': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
  };
  'Custom Rates Add Rule Save Complete': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Add Rule Save Validation Error': {
    rule_name_error?: string;
    rule_rate_error?: string;
    rule_date_error?: string;
  };
  'Custom Rates Add Rule Save Failed': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    reason: string;
  };
  'Custom Rates Update Rule Clicked': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Update Rule Cancel Clicked': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Update Rule Save Clicked': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Update Rule Save Complete': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Update Rule Save Validation Error': {
    rule_name_error?: string;
    rule_rate_error?: string;
    rule_date_error?: string;
  };
  'Custom Rates Update Rule Save Failed': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    reason: string;
  };
  'Custom Rates Deactivate Rule Clicked': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Deactivate Rule Cancel Clicked': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Deactivate Rule Save Clicked': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Deactivate Rule Complete': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    rule_id: string;
  };
  'Custom Rates Deactivate Rule Failed': {
    listing_id: number;
    rule_name: string;
    rule_rate: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_rate: number;
    rule_rate_minus_min_rate: number;
    rule_rate_min_rate_ratio: number;
    reason: string;
  };
  // ************ Custom Min Stays ************
  'Custom Stays Loaded': {
    status: 'Live' | 'Expired';
    num_rules_total: number;
  };
  'Custom Stays External Link Clicked': {
    article_name: 'HelpCenter';
  };
  'Custom Stays Add Rule Clicked': {
    min_stay: number;
  };
  'Custom stays Add Rule Cancel Clicked': {
    min_stay: number;
  };
  'Custom stays Add Rule Save Clicked': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
  };
  'Custom stays Add Rule Save Complete': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
  };
  'Custom stays Add Rule Save Validation Error': {
    rule_name_error?: string;
    rule_stay_error?: string;
    rule_date_error?: string;
  };
  'Custom stays Add Rule Save Failed': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    reason: string;
  };
  'Custom stays Update Rule Clicked': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
  };
  'Custom stays Update Rule Cancel Clicked': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
  };
  'Custom stays Update Rule Save Clicked': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
    prev_rule_stay: number;
    prev_rule_start_date: string;
    prev_rule_end_date: string;
  };
  'Custom stays Update Rule Save Complete': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
  };
  'Custom stays Update Rule Save Validation Error': {
    rule_name_error?: string;
    rule_stay_error?: string;
    rule_date_error?: string;
  };
  'Custom stays Update Rule Save Failed': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    reason: string;
  };
  'Custom stays Deactivate Rule Clicked': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
  };
  'Custom stays Deactivate Rule Cancel Clicked': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
  };
  'Custom stays Deactivate Rule Save Clicked': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
  };
  'Custom stays Deactivate Rule Complete': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    rule_id: string;
  };
  'Custom stays Deactivate Rule Failed': {
    listing_id: number;
    rule_name: string;
    rule_stay: number;
    start_date: string;
    end_date: string;
    num_days: number;
    num_days_in_future: number;
    min_stay: number;
    rule_stay_minus_min_stay: number;
    rule_stay_min_stay_ratio: number;
    reason: string;
  };
  // ************ Onboarding ************
  'Onboarding Refetch on Logout': { is_self_signup: boolean };
  'Property Wizard Complete': { is_self_signup: boolean };
  'Stripe Account Setup Complete': { is_self_signup: boolean };
  'Permits Intro Egress Next Clicked': {
    permit_requirements_count: number;
    permits_added_count: number;
    is_self_signup: boolean;
  };
  'Permit Upload': {
    operation: 'New' | 'Edit';
    permit_requirement: string | null;
    is_self_signup: boolean;
  };
  'Permit Upload File Attach File Clicked': {
    operation: 'New' | 'Edit';
    permit_requirement: string | null;
    is_self_signup: boolean;
  };
  'Permit Upload File Attached': {
    operation: 'New' | 'Edit';
    permit_requirement: string | null;
    is_self_signup: boolean;
  };
  'Permits Upload Egress Save Clicked': {
    operation: 'New' | 'Edit';
    permit_requirement: string | null;
    is_self_signup: boolean;
  };
  'Permits Upload Egress Close Clicked': {
    operation: 'New' | 'Edit';
    permit_requirement: string | null;
    is_self_signup: boolean;
  };
  'Permits List Egress Back Clicked': {
    permit_requirements_count: number;
    permits_added_count: number;
    is_self_signup: boolean;
  };
  'Permits List Egress Later Clicked': {
    permit_requirements_count: number;
    permits_added_count: number;
    is_self_signup: boolean;
  };
  'Permits List Egress Save Clicked': {
    permit_requirements_count: number;
    permits_added_count: number;
    is_self_signup: boolean;
  };
  'Permits List Egress External Resource Clicked': {
    permit_requirement: string;
    uri?: string | null;
    is_self_signup: boolean;
  };
  // ************ RFI ************
  'New RFI Next Clicked': CreatePropertyIntakeFields & {
    step: StepId;
    stepIndex: number;
    isPrequalifiedForSelfSignup: boolean;
  };
  'New RFI Completed': CreatePropertyIntakeFields & {
    isPrequalifiedForSelfSignup: boolean;
  };
  // ************ Dashboard Banner ************
  'Info Banner CTA Clicked': {
    message: string;
    cta_text: string;
    cta_link: string;
  };
  'Info Banner Dismissed': {
    message: string;
    cta_text: string;
    cta_link: string;
  };
  'Info Banner Shown': {
    message: string;
    cta_text: string;
    cta_link: string;
  };
}

export interface SegmentPageEventProperties {
  // ************ Dashboard ************
  'Account': {
    primary_billing_contact: 'yes' | 'no';
  };
  // ************ Dashboard ************
  'Dashboard': {
    num_listings: number;
  };
  // ************ Performance ************
  'Performance': {
    error?: boolean;
  };
  'Performance Metric Breakdown': {
    error?: boolean;
  };
  // ************ Auth ************
  'Login': {
    impersonate_user?: string;
  };
  'Forgot Password': {
    login_attempts: string | string[] | undefined;
  };
  // ************ Bookings ************
  'Bookings Calendar': {
    view: 'week' | 'month';
    listings_count: number;
  };
  'Cancel Booking Info Page': {
    booking_id: string;
  };
  'Cancel Booking Reasons Page': {
    booking_id: string;
  };
  'Cancel Booking Recap Page': {
    booking_id: string;
    category: OCReason['id'];
    subreason: OCSubreason['id'];
    scenario: OCScenario;
  };
  'Cancel Booking Fee Page': {
    booking_id: string;
    category: OCReason['id'];
    subreason: OCSubreason['id'];
    scenario: OCScenario;
    fee_amount: number;
  };
  // ************ Listings ************
  'Listings List': {
    listings_count: number;
    num_listings_onhold: number;
    num_listings_incomplete: number;
    num_listings_inprogress: number;
    num_listings_active: number;
    num_listings_resigned: number;
  };
  'Listings List Add New Listing': { listings_count: number };
  'Listing Details': {
    listing_status?: ListingStatus;
  };
  'Listing Details Overview': {
    listing_status?: ListingStatus;
  };
  'Listing Details All Photos Viewed': {
    photos_count: number;
  };
  'Listing Details Reviews List': {
    record_count: number;
    record_total: number;
    reviews_loaded: number;
    reviews_avg_rating: number;
    filters_rating: string | null;
    filters_check_in_date: DateRangeLabel;
    filters_source_count: number | null;
    filters_source: string;
  };
  'Listing Details Edit Property Name': undefined;
  // ************ Referrals ************
  'Referrals': {
    referrals_total_count: number;
    referrals_pending_count: number;
    referrals_complete_count: number;
    referrals_dollars: number;
  };
  // ************ Reimbursements ************
  'Reimbursements List': {
    reimbursements_count: number;
  };
  'Reimbursement Details': {
    reimbursement_status?: RequestStatus;
    amount_requested: number;
    amount_reimbursed: number | string;
  };
  // ************ Report an Incident ************
  'Report an Incident': {
    qualified_bookings: number;
  };
  'Requests List': {
    requests_count: number;
  };
  'Request Details': {
    request_type: string;
    request_status: string;
    request_comments: number;
  };
  'Listing Details Fees & Rules': {
    listing_status?: ListingStatus;
    fees_count: number;
    cleaning_fee_amount: number;
  };
  'Listing Details Edit Cleaning Fee': {
    fee_amount: number;
  };
  'Listing Details Review Details': {
    review_rating: number;
    comment_count: number;
    review_source?: string;
    source: 'Listing Details';
  };
  'Listing Details Update Amenities': {
    listing_status?: ListingStatus;
    amenities_count: number;
  };
  'Submit Request Form': undefined;
  // ************ Licenses ************
  'Listing Details Licenses': {
    license_count: number;
    listing_status?: ListingStatus;
  };
  'Listing Details Add License': {
    license_count: number;
    listing_status?: ListingStatus;
  };
  'Listing Details Edit License': {
    license_count: number;
    listing_status?: ListingStatus;
  };
  // ************ Min Rate Editor ************
  'Min Rate Editor': {
    min_rate: number;
  };
  'Maintenance Page': undefined;
  // ************ Onboarding ************
  'Account Setup Page': { is_self_signup: boolean };
  'Onboarding Page': {
    is_self_signup: boolean;
  };
  'Permits Intro': {
    ingress_origin:
      | 'Property Overview'
      | 'Onboarding Dashboard'
      | 'Active Dashboard';
    is_self_signup: boolean;
  };
  'Permits List': {
    permit_requirements_count: number;
    permits_added_count: number;
    ingress_origin:
      | 'Property Overview'
      | 'Onboarding Dashboard'
      | 'Active Dashboard';
    is_self_signup: boolean;
  };
  'Permit Upload': {
    permit_requirement: string | null;
    operation: 'New' | 'Edit';
    is_self_signup: boolean;
  };
  'Progress Page': { is_self_signup: boolean };
  'Property Overview Page': { is_self_signup: boolean };
  // ************ Financial Reports ************
  'Reports Credits': {
    num_credits: number;
    num_fees: number;
  };
  // ************ RFI ************
  'New RFI': CreatePropertyIntakeFields & {
    step: StepId;
    stepIndex: number;
    isPrequalifiedForSelfSignup: boolean;
  };
  'Self Signup Confirmation Page': undefined;
  'Self Signup Qualification Page': undefined;
}

type SegmentTrackEvents = keyof SegmentTrackEventProperties;
type SegmentPageEvents = keyof SegmentPageEventProperties;

/**
 * Convert all allowed combinations to function overload
 */
export const trackEvent = async <Event extends SegmentTrackEvents>(
  event: Event,
  properties?: Event extends keyof SegmentTrackEventProperties
    ? SegmentTrackEventProperties[Event]
    : void
) => {
  const isMobile = window.location.pathname.startsWith('/mobile');

  try {
    const commonProperties = await getCommonProperties();

    if (isMobile) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'track',
            event,
            properties: {
              ...commonProperties,
              ...(properties ?? {}),
            },
          })
        );
      }
    } else {
      window.analytics?.track(event, {
        ...commonProperties,
        ...(properties ?? {}),
      });
    }
    // Show event properties if they exist
    console.info(
      `Tracked event: ${event}` +
        (properties ? ` Properties: ${JSON.stringify(properties)}` : '')
    );
  } catch (error) {
    console.error(`Error tracking event: ${error}`);
  }
};

export const pageEvent = async <Event extends SegmentPageEvents>(
  event: Event,
  properties?: Event extends keyof SegmentPageEventProperties
    ? SegmentPageEventProperties[Event]
    : void
) => {
  const isMobile = window.location.pathname.startsWith('/mobile');

  try {
    const commonProperties = await getCommonProperties();

    if (isMobile) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'page',
            event,
            properties: {
              ...commonProperties,
              ...(properties ?? {}),
            },
          })
        );
      }
    } else {
      window.analytics?.page(event, {
        ...commonProperties,
        ...(properties ?? {}),
      });
    }
    console.info(`Tracked page event: ${event}`);
  } catch (error) {
    console.error(`Error tracking page event: ${error}`);
  }
};
