import { Slot } from '@radix-ui/react-slot';
import React, { useEffect, useId, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AlertIcon } from 'components/Icons';
import { BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

import { HelpText } from './HelpText';
import { Label } from '../shared/Label';

import type { ReactNode } from 'react';

export type FormFieldProps = BaseProps & {
  label: React.ReactNode;
  helpMessage?: string;
  errorMessage?: string;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  showRequiredAsterisk?: boolean;
  isLoading?: boolean;
};

export const FormField = ({
  label,
  children,
  invertTheme,
  helpMessage,
  errorMessage,
  className,
  isLoading,
  showRequiredAsterisk = false,
  ...props
}: FormFieldProps) => {
  const inputRef = useRef<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >(null);
  const inputId = useId();
  const fieldRef = useRef<HTMLDivElement>(null);
  const descriptionId = `${inputId}-description`;
  const labelId = `${inputId}-label`;
  const hasError = Boolean(errorMessage);

  useEffect(() => {
    inputRef.current?.setCustomValidity?.(errorMessage || '');
  }, [errorMessage]);

  const HelpTextContent = errorMessage ? (
    <>
      <div className="self-start pt-0.5">
        <AlertIcon
          className="text-error dark:text-error-light"
          size="0.75rem"
        />
      </div>
      {errorMessage}
    </>
  ) : (
    helpMessage
  );

  // Passing a string to <Slot /> will prevent the string from being rendered. Wrap in Fragment to fix this.
  const renderedChildren =
    typeof children === 'string' ? <span>{children}</span> : children;

  return (
    <div
      ref={fieldRef}
      className={cn(
        'group flex w-full flex-col',
        {
          'theme-invert': invertTheme,
          'text-disabled-light dark:text-disabled-dark': props.disabled,
          'text-default dark:text-white': !props.disabled,
        },
        className
      )}
    >
      <Label className="pb-1" htmlFor={inputId} id={labelId}>
        {label}{' '}
        {showRequiredAsterisk && !props.disabled && (
          <span className="text-error">*</span>
        )}
      </Label>
      <Slot
        aria-describedby={descriptionId}
        aria-labelledby={labelId}
        id={inputId}
        ref={inputRef}
        {...props}
      >
        {isLoading ? (
          // Wrap in Fragment to avoid FormField trying to pass a ref to Skeleton
          <>
            <Skeleton height={44} />
          </>
        ) : (
          renderedChildren
        )}
      </Slot>
      {!!HelpTextContent && (
        <HelpText
          className="flex items-center gap-2 pt-1"
          error={hasError}
          id={descriptionId}
        >
          {HelpTextContent}
        </HelpText>
      )}
    </div>
  );
};
