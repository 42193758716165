import { Title } from '@radix-ui/react-dialog';

import { getFontSize, HeadingProps } from 'components/Heading';
import { cn } from 'utils/tailwind';

export type DialogTitleProps = HeadingProps;

export const DialogTitle = ({
  className,
  level = 2,
  size = 'sm',
  ...props
}: DialogTitleProps) => {
  const fontSize = getFontSize({ level, size });

  // This component replicates Heading but directly in Title to avoid radix errors/accessibility issues
  return (
    <Title
      className={cn(
        'font-heading text-default dark:text-white',
        {
          'text-heading-6 lg:text-heading-6-lg font-medium lg:font-semibold':
            fontSize === 'xs',
          'text-heading-5 lg:text-heading-5-lg font-semibold lg:font-semibold':
            fontSize === 'sm',
          'text-heading-4 lg:text-heading-4-lg font-semibold lg:font-semibold':
            fontSize === 'md',
          'text-heading-3 lg:text-heading-3-lg font-bold lg:font-bold':
            fontSize === 'lg',
          'text-heading-2 lg:text-heading-2-lg font-extrabold lg:font-bold':
            fontSize === 'xl',
          'text-heading-1 lg:text-heading-1-lg font-extrabold lg:font-bold':
            fontSize === '2xl',
        },
        'px-4 pb-4 md:px-6',
        'group-data-[fullscreen]/dialog:px-6 group-data-[has-close-button]/dialog:pr-[60px] group-data-[scrollable]/dialog:pb-4 group-data-[scrollable]/dialog:md:pb-6 group-data-[scrollable]/dialog:md:pt-2',
        className
      )}
      {...props}
    />
  );
};
