/* eslint-disable testing-library/no-debugging-utils */

import {
  Session,
  getServerSession as _getServerSession,
  AuthOptions,
  User,
  Account,
} from 'next-auth';
import { JWT } from 'next-auth/jwt';
import { ProviderType } from 'next-auth/providers';
import CredentialsProvider from 'next-auth/providers/credentials';
import SalesforceProvider, {
  SalesforceProfile,
} from 'next-auth/providers/salesforce';

import { fetchWithLogger } from 'utils/fetch/fetchWithLogger';

function tokenIntrospection(hours = 24) {
  return Date.now() + 1000 * 60 * 60 * hours;
}

interface BasicInfoResponse {
  accountId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  managementTier: string;
  billingContact: boolean;
  mailingStreet: string;
  mailingCity: string;
  mailingState: string;
  mailingPostalCode: string;
  mailingCountry: string;
  hasActiveListing: boolean;
  isOnboarding: boolean;
}

interface BaseSalesforceAccount {
  provider: string;
  type: ProviderType;
  providerAccountId: string;
  access_token?: string;
  refresh_token?: string;
  signature: string;
  token_format: string;
  scope?: string;
  id_token?: string;
  instance_url: string;
  id: string; // In format 'https://test.salesforce.com/id/xxxxxxx/<userId>',
  token_type?: string;
  issued_at: string; // In number format of milisecond epoch time
}

interface SalesforceAccount
  extends BaseSalesforceAccount,
    Omit<Account, 'userId'> {
  state: { supportUsername: string };
}

interface CredentialsUser extends User {
  username: string;
  supportUsername?: string;
}

async function getExperienceAPIBasicInfo(
  accessToken: string
): Promise<BasicInfoResponse> {
  const userInfoResponse = (await (
    await fetchWithLogger(
      `${process.env.NEXT_PUBLIC_EXPERIENCE_API_URL}/account/basicInfo`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
  ).json()) as BasicInfoResponse;

  return userInfoResponse;
}

async function getJwtUser({
  accessToken,
  username,
  impersonatingProvider,
}: {
  accessToken: string;
  username: string;
  impersonatingProvider: string | undefined;
}): Promise<JWT['user']> {
  const content = accessToken.split('.');

  const payload = Buffer.from(content[1], 'base64').toString('ascii');
  const { ci: clientId, ui: userId } = JSON.parse(payload);

  const profile = await getExperienceAPIBasicInfo(accessToken);

  const user = {
    accessToken,
    accessTokenExpires: tokenIntrospection(),
    profile: {
      ...profile,
      username,
      userId,
      clientId,
      contactId: clientId,
    },
    impersonator: impersonatingProvider
      ? {
          provider: impersonatingProvider,
        }
      : null,
  } as User;

  return user;
}

/**
 * Takes a token, and returns a new token with updated
 * `accessToken` and `accessTokenExpires`. If an error occurs,
 * returns the old token and an error property
 */
async function refreshAccessToken({
  token,
  supportUsername,
}: {
  token: JWT;
  supportUsername?: string;
}): Promise<JWT> {
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_EXPERIENCE_API_URL}/salesforce/refresh`,
      {
        method: 'PATCH',
        headers: {
          Authorization: token.user.accessToken || '',
        },
        body: JSON.stringify({
          supportUsername,
        }),
      }
    );

    const data = (await res.json()) as {
      accessToken: string;
    };

    const { accessToken } = data;

    const newJwtUser = await getJwtUser({
      accessToken,
      username: supportUsername || token.user.username,
      impersonatingProvider: token.user.impersonator?.provider,
    });

    const newToken = {
      ...token,
      user: newJwtUser,
    };

    return newToken;
  } catch (error) {
    console.error('Error refreshing access token', error);
    return {
      ...token,
      error: 'RefreshAccessTokenError' as const,
    };
  }
}

// Can't access type of credentials outside of AuthOptions so we use this
export interface NextAuthCredentials {
  username: string;
  password: string;
  supportUsername?: string;
  accessToken?: string;
}

export const authOptions: AuthOptions = {
  providers: [
    CredentialsProvider({
      name: 'Salesforce',
      credentials: {
        username: { label: 'Username', type: 'text' },
        password: { label: 'Password', type: 'password' },
        supportUsername: { label: 'Support username', type: 'text' },
        accessToken: {
          type: 'hidden',
        },
      },
      // This populates the user object in jwt()
      async authorize(credentials): Promise<CredentialsUser | null> {
        if (!credentials) {
          console.error('No credentials provided');
          return null;
        }

        const usingCredentials = credentials.username && credentials.password;
        const usingAccessToken = credentials.accessToken;
        if (!usingCredentials && !usingAccessToken) {
          return null;
        }

        let accessToken: string | undefined = credentials.accessToken;

        try {
          if (usingCredentials) {
            const authRawResponse = await fetchWithLogger(
              `${process.env.NEXT_PUBLIC_EXPERIENCE_API_URL}/salesforce/login`,
              {
                method: 'POST',
                headers: {
                  'X-API-Key': process.env
                    .NEXT_PUBLIC_EXPERIENCE_API_KEY as string,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  username: credentials.username,
                  password: credentials.password,
                  supportUsername: credentials.supportUsername,
                }),
              }
            );

            if (!authRawResponse.ok) {
              const rawResponse = await authRawResponse.text();
              console.error('salesforce/login error: ', rawResponse);

              const responseBody = await JSON.parse(rawResponse);

              // FE needs to display specific message for this case
              if (responseBody?.error === 'invalid_app_access') {
                throw new Error(`403 invalid_app_access`);
              }
              throw new Error(
                `${authRawResponse.status} ${authRawResponse.statusText}`
              );
            }

            accessToken = (await authRawResponse.json()).accessToken;
          }

          if (!accessToken) {
            throw new Error('No access token');
          }

          return {
            // We don't use this id so it's left blank. We attach profile.id and impersonator.id later based
            // on what's appropriate
            id: '',
            username: credentials.username,
            supportUsername: credentials.supportUsername,
            accessToken,
            accessTokenExpires: tokenIntrospection(),
          };
        } catch (e) {
          if (e instanceof Error) {
            console.error(`Error during user authentication: ${e.message}`);
          }

          console.error(`Error during user authentication: ${e}`);
          throw e;
        }
      },
    }),
    // Owner Web supports an alternate authentication model.
    // While signed in w/ Salesforce no Experience API requests will work except to impersonate an owner.
    SalesforceProvider({
      name: 'Employee via Salesforce',
      clientId: process.env.SALESFORCE_CLIENT_ID || '',
      clientSecret: process.env.SALESFORCE_CLIENT_SECRET || '',
      idToken: true,
      wellKnown: `${process.env.SALESFORCE_URL_LOGIN}/.well-known/openid-configuration`,
      authorization: { params: { scope: 'openid api refresh_token' } },
      token: {
        async request({ client, params, checks, provider }) {
          // Don't send `state` to Salesforce OAuth endpoint since this causes OAuth errors. We save
          // the state to the response for further processing in JWT callback.
          const { state: stateJsonStr, ...restParams } = params;

          const response = await client.callback(
            provider.callbackUrl,
            restParams,
            checks
          );

          try {
            const state = JSON.parse(stateJsonStr || '{}') as Account['state'];

            response.state = state;

            return {
              tokens: response,
            };
          } catch (e) {
            console.error('Error parsing state:', e);
            throw e;
          }
        },
      },
      userinfo: {
        async request({ provider, tokens, client }) {
          //@ts-expect-error - 'token' is of type TokenSetParameter and userinfo is expecting TokenSet
          return await client.userinfo(tokens, {
            //@ts-expect-error - not sure why this is not working
            params: provider.userinfo?.params,
          });
        },
      },
      profile(profile: SalesforceProfile, tokenSet) {
        return {
          id: profile.user_id,
          state: tokenSet.state,
          accessToken: tokenSet.access_token || '',
          accessTokenExpires: tokenIntrospection(),
          ...profile,
        };
      },
    }),
  ],
  callbacks: {
    async jwt({ token, user, account, trigger, session }) {
      if (trigger === 'update' && session?.forceRefresh) {
        return await refreshAccessToken({
          token,
          supportUsername: session?.supportUsername,
        });
      }

      // Initial sign in
      if (account && user) {
        let accessToken: string | undefined;
        let username: string | undefined;
        let impersonatingProvider: string | undefined;

        if (account.provider === 'credentials') {
          const typedUser = user as CredentialsUser;

          accessToken = typedUser.accessToken;
          username = typedUser.username;
          impersonatingProvider = typedUser.supportUsername && 'credentials';
        } else if (account.provider === 'salesforce') {
          const typedUser = user as typeof user & SalesforceProfile;
          const typedAccount = account as SalesforceAccount;
          impersonatingProvider = 'salesforce';
          // TODO Where to insert this
          username = typedUser.preferred_username;
          const sfAccessToken = account.access_token;
          const sfRefreshToken = account.refresh_token;

          // User the Salesforce OAuth access and refresh token to sign in with the Experience API
          const response = await fetchWithLogger(
            `${process.env.NEXT_PUBLIC_EXPERIENCE_API_URL}/salesforce/login`,
            {
              method: 'POST',
              headers: {
                'x-api-key': process.env
                  .NEXT_PUBLIC_EXPERIENCE_API_KEY as string,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                salesforceUserId: typedUser.id,
                salesforceAuthToken: sfAccessToken,
                salesforceRefreshToken: sfRefreshToken,
                salesforceAuthTokenIsDecrypted: true,
                supportUsername: typedAccount.state.supportUsername,
              }),
            }
          );

          const responseBody = await response.json();
          accessToken = responseBody.accessToken;
        }

        if (!accessToken) {
          throw new Error('No access token');
        }

        // Extract below to a separate function to be used in refresh logic as well

        const jwtUser = await getJwtUser({
          accessToken,
          username: username || '',
          impersonatingProvider,
        });

        const jwt: JWT = {
          user: jwtUser,
        };

        return jwt;
      }

      // Return token if before token expiration
      if (Date.now() < token.user.accessTokenExpires) {
        return token;
      }

      // Access token has expired, try to update it, on error old token is returned (jwt only takes token as return option)
      // Any additional error handling can be done by accessing token.error in session callback below
      return await refreshAccessToken({ token });
    },
    async session({ session, token }) {
      return { ...session, user: token.user, error: token.error };
    },
  },
  pages: {
    signIn: '/login',
  },
  logger: {
    error: (message) => console.error(`NextAuth Logger: ${message}`),
    warn: (message) => console.warn(`NextAuth Logger: ${message}`),
    debug: (message) => console.debug(`NextAuth Logger: ${message}`),
  },
};

export function isSalesforceUser(session: Session | null) {
  const isImpersonating = getIsImpersonating(session);

  return !!session?.user.impersonator && !isImpersonating;
}

export function canImpersonate(
  session: Session | null,
  status: 'unauthenticated' | 'loading' | 'authenticated'
) {
  return status === 'authenticated' && !!session?.user?.impersonator;
}

export function getIsImpersonating(session: Session | null) {
  return !!session?.user.impersonator && !!session?.user.profile;
}

/**
 * Returns the current session for server components.
 * * [Documentation](https://next-auth.js.org/configuration/nextjs#getserversession)
 */
export function getServerSession() {
  return _getServerSession(authOptions);
}
