import { Close, Description, Root, Title } from '@radix-ui/react-toast';
import { ReactNode } from 'react';

import { CloseIcon } from 'components/Icons';
import { cn } from 'utils/tailwind';

const AUTO_DISMISS_DURATION = 5000;

export interface ToastProps {
  type?: 'info' | 'success' | 'warning' | 'error';
  icon?: ReactNode;
  title?: string;
  content: string;
  dismissable?: boolean;
  onOpenChange?: (open: boolean) => unknown;
  placement?: 'left' | 'right' | 'center';
  className?: string;
}

export const Toast = ({
  type = 'info',
  title,
  icon,
  content,
  onOpenChange,
  dismissable = false,
  placement = 'right',
  className,
}: ToastProps) => {
  return (
    <Root
      open
      duration={dismissable ? undefined : AUTO_DISMISS_DURATION}
      onOpenChange={onOpenChange}
      className={cn(
        'mb-2 flex w-80 flex-row rounded p-4 drop-shadow lg:w-[600px]',
        {
          'bg-island-dark text-white dark:bg-white dark:text-island-900':
            type === 'info',
          'bg-success dark:bg-success-light text-white': type === 'success',
          'bg-warning dark:bg-warning-light text-island-900':
            type === 'warning',
          'bg-error dark:bg-error-light text-white dark:text-island-900':
            type === 'error',
          'pr-8': dismissable,
          'self-center': placement === 'center',
          'self-start': placement === 'left',
          'self-end': placement === 'right',
        },
        className
      )}
    >
      {Boolean(icon) && <div className="flex pr-4 text-[24px]">{icon}</div>}
      <div className="flex-1">
        {Boolean(title) && (
          <Title className="pb-1 font-heading text-heading-6 font-medium uppercase lg:text-heading-6-lg lg:font-semibold">
            {title}
          </Title>
        )}
        <Description className="text-base font-bold">{content}</Description>
      </div>
      {dismissable && (
        <Close
          aria-label="Close"
          className="absolute right-0 top-0 p-2 text-sm"
        >
          <CloseIcon />
        </Close>
      )}
    </Root>
  );
};
