'use client';

import { ReactNode, type Ref } from 'react';

import { cn } from 'utils/tailwind';

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type HeadingSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps {
  ref?: Ref<HTMLHeadingElement>;
  /**
   * The level of the heading tag to render.
   * @default 3
   * */
  level?: HeadingLevel;
  /**
   * The size of the heading.  This defaults to the appropriate size for the chosen level.
   * */
  size?: HeadingSize;
  className?: string;
  children: ReactNode;
}

export const Heading = ({
  ref,
  level = 3,
  size,
  className,
  children,
}: HeadingProps) => {
  const Element = `h${level}` as HeadingTag;
  const fontSize = getFontSize({ level, size });

  return (
    <Element
      ref={ref}
      className={cn(
        'font-heading text-default dark:text-white',
        {
          'text-heading-6 lg:text-heading-6-lg font-medium lg:font-semibold':
            fontSize === 'xs',
          'text-heading-5 lg:text-heading-5-lg font-semibold lg:font-semibold':
            fontSize === 'sm',
          'text-heading-4 lg:text-heading-4-lg font-semibold lg:font-semibold':
            fontSize === 'md',
          'text-heading-3 lg:text-heading-3-lg font-bold lg:font-bold':
            fontSize === 'lg',
          'text-heading-2 lg:text-heading-2-lg font-extrabold lg:font-bold':
            fontSize === 'xl',
          'text-heading-1 lg:text-heading-1-lg font-extrabold lg:font-bold':
            fontSize === '2xl',
        },
        className
      )}
    >
      {children}
    </Element>
  );
};

Heading.displayName = 'Heading';

export function getFontSize({
  level,
  size,
}: {
  level: HeadingLevel;
  size?: HeadingSize;
}): HeadingSize {
  if (size) {
    return size;
  }

  switch (level) {
    case 6:
      return 'xs';
    case 5:
      return 'sm';
    case 4:
      return 'md';
    case 3:
      return 'lg';
    case 2:
      return 'xl';
    case 1:
      return '2xl';
  }
}
