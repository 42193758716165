'use client';

import { SignInOptions, signIn, useSession } from 'next-auth/react';
import { useQueryStates } from 'nuqs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ButtonLink } from 'components/ButtonLink';
import { Button } from 'components/Buttons';
import { FormField, Input } from 'components/Forms';
import { Heading } from 'components/Heading';
import { AlertIcon } from 'components/Icons';
import { Link } from 'components/Link';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useToasts } from 'components/Toast';
import { fetchWithAuth } from 'hooks/auth';
import { useImpersonate } from 'hooks/useImpersonate';
import { useSegmentPageView } from 'hooks/useSegmentPageView';
import { isSalesforceUser, NextAuthCredentials } from 'utils/auth';
import { trackEvent } from 'utils/segment';
import { loginSearchParamsParsers } from 'utils/validation/searchParams';

interface LoginFormInputs {
  email: string;
  password: string;
  impersonateUsername?: string;
}

// Login page
export default function Login() {
  const [queryStates] = useQueryStates(loginSearchParamsParsers);
  const { callbackUrl, impersonateUser, support } = queryStates;
  const isImpersonating = !!impersonateUser && impersonateUser !== 'false';
  const supportMode = impersonateUser ?? support ?? '';
  const [signingInWithSF, setSigningInWithSF] = useState(isImpersonating);
  const [fullPageLoading, setFullPageLoading] = useState(false);
  const { data: session, status } = useSession();
  const loginAttempts = useRef(0);

  const isSessionSalesforceUser = isSalesforceUser(session);

  const { impersonateAsync, isPending: impersonatePending } = useImpersonate({
    callbackUrl,
    onError: async () => {
      try {
        await signInWithSalesforce();
      } catch {
        showToast({
          type: 'error',
          icon: <AlertIcon />,
          placement: 'left',
          content: `There was an error impersonating. Please try again.`,
        });
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setFocus,
    setValue,
    trigger,
    clearErrors,
    getValues,
  } = useForm<LoginFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { showToast } = useToasts();

  const impersonateUserFormData = getValues('impersonateUsername');

  const signInWithSalesforce = useCallback(async () => {
    setSigningInWithSF(true);
    const targetImpersonateUser =
      impersonateUserFormData?.trim() ?? impersonateUser;

    try {
      const hasSupportUsername =
        targetImpersonateUser && targetImpersonateUser !== 'true';

      await signIn(
        'salesforce',
        // If no user was provided take Evolver to impersonate page
        {
          callbackUrl: !hasSupportUsername
            ? '/internal/impersonate'
            : undefined,
        },
        {
          state: JSON.stringify({
            supportUsername: hasSupportUsername
              ? targetImpersonateUser
              : undefined,
          }),
        }
        // This request is pretty fast, but the follow-up request to load the SF page is a lot slower.
        // So let's just leave the spinner going until something goes wrong or the page reloads.
      );
    } catch {
      setSigningInWithSF(false);
    }
  }, [setSigningInWithSF, impersonateUser, impersonateUserFormData]);

  useSegmentPageView('Login', {
    properties: {
      impersonate_user: isImpersonating
        ? impersonateUser
        : Boolean(support).toString(),
    },
  });

  useEffect(() => {
    // If it's set to a username populate the field
    // If it's just set to 'true' don't populate the field
    if (isImpersonating && impersonateUser !== 'true') {
      setValue('impersonateUsername', impersonateUser);
    }
  }, [isImpersonating, impersonateUser, setValue]);

  useEffect(() => {
    const canceled = { value: false };
    const runEffect = async () => {
      // Not attempting to impersonate
      if (!impersonateUser || impersonateUser === 'false') {
        // Case 1: If already logged in then redirect
        if (status === 'authenticated') {
          // Verify the token is still valid before navigating into the app
          setFullPageLoading(true);

          fetchWithAuth(
            `${process.env.NEXT_PUBLIC_EXPERIENCE_API_URL}/account/basicInfo`,
            session?.user?.accessToken
          )
            .then(() => {
              if (!canceled.value) {
                window.location.href = callbackUrl;
              }
            })
            .catch(() => {
              // Do nothing, leave the user on the login page if the token is invalid
              if (!canceled.value) {
                setFullPageLoading(false);
              }
            });
        }

        // Case 2: not logged in, no redirect
        return;
      }

      // Attempting to Impersonate
      // Case 3: user is attempting to impersonate but not authenticated
      if (status !== 'authenticated') {
        try {
          setFullPageLoading(true);
          await signInWithSalesforce();
        } finally {
          if (!canceled.value) {
            setFullPageLoading(false);
          }
        }
      }
      // Case 4: Evolver already logged in attempting to impersonate or use support mode
      else if (impersonateUser === 'true' || supportMode === 'true') {
        // If able to impersonate then redirect to internal page otherwise use redirect url
        window.location.href = isSessionSalesforceUser
          ? '/internal/impersonate'
          : callbackUrl;
      }
      // Case 5: evolver already logged in but not impersonating yet, in this case attempt to impersonate
      else {
        try {
          setFullPageLoading(true);
          await impersonateAsync(impersonateUser);
        } catch (e) {
          console.error('Error impersonating', e);
        } finally {
          if (!canceled.value) {
            setFullPageLoading(false);
          }
        }
      }
    };
    runEffect();

    return () => {
      canceled.value = true;
    };
  }, [
    impersonateAsync,
    impersonateUser,
    isSessionSalesforceUser,
    callbackUrl,
    signInWithSalesforce,
    status,
    supportMode,
    session?.user?.accessToken,
  ]);

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data, e) => {
    // Avoid using default GET form submission
    e?.preventDefault();

    trackEvent('Login Button', {
      email: data.email,
      impersonate_user: data.impersonateUsername,
    });

    try {
      const credentials: SignInOptions & NextAuthCredentials = {
        username: data.email,
        password: data.password,
        // Use redirect false to handle additional signin validation, we redirect on success
        redirect: false,
        supportUsername: data?.impersonateUsername?.trim() ?? '',
      };

      const result = await signIn('credentials', credentials);

      if (result?.error) {
        loginAttempts.current += 1;

        if (result?.error?.includes('invalid_app_access')) {
          trackEvent('Login Failed', {
            email: data.email,
            reason: 'Permissions',
          });

          showToast({
            type: 'error',
            title: "We couldn't log you in",
            icon: <AlertIcon />,
            placement: 'center',
            content:
              'Your account may be missing the permissions to use the Owner Account. ' +
              'If this issue persists, please reach out to us at (877) 818-1014.',
          });
        } else if (result?.error?.includes('Unauthorized')) {
          trackEvent('Login Failed', {
            email: data.email,
            reason: 'Credentials',
          });

          showToast({
            type: 'error',
            title: "We couldn't log you in",
            icon: <AlertIcon />,
            placement: 'center',
            content:
              'Your email and/or password did not match what we have in our system. ' +
              'Please double check and try again.',
          });
        } else {
          trackEvent('Login Failed', {
            email: data.email,
            reason: 'Other',
          });

          showToast({
            type: 'error',
            title: 'Login failed',
            icon: <AlertIcon />,
            placement: 'center',
            content: 'Something went wrong. Please try again in a few minutes.',
          });

          // log the unexpected login failure
          console.error(`Unexpected login error: ${result?.error}`);
        }
      } else {
        trackEvent('Login Complete', {
          email: data.email,
          impersonate_user: data.impersonateUsername,
        });
        // Redirect user if successfully signed in
        window.location.href = callbackUrl;
      }
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      console.error(`Error logging in: ${error}`);
    }
  };

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  return (
    <div className="content-container rounded-lg bg-white p-6 md:mx-auto md:mt-16 md:max-w-[31.625rem] md:p-12 md:shadow-lg lg:max-w-[32.625rem] lg:p-14">
      <div className="flex justify-center">
        <div className="rounded bg-island-50 px-3 py-1 ">
          <Heading level={5}>Evolve Owner Account</Heading>
        </div>
      </div>
      {fullPageLoading ? (
        <div className="mt-4 flex justify-center md:mt-6 lg:mt-10">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <Heading className="mt-11" level={3}>
            Welcome
          </Heading>
          <p className="mt-2 text-lg lg:mt-3">Log in to your account</p>

          <form noValidate className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col items-start gap-4">
              <FormField errorMessage={errors.email?.message} label="Email">
                <Input
                  type="text"
                  {...register('email', {
                    required: 'Enter an email',
                    onBlur: async (e) => {
                      if (e.target.value) {
                        await trigger('email');
                      }
                    },
                    onChange: () => clearErrors('email'),
                  })}
                />
              </FormField>

              {supportMode && (
                <FormField label="Username to Impersonate">
                  <Input
                    type="text"
                    {...register('impersonateUsername', {
                      required: 'Enter an email',
                      onBlur: async (e) => {
                        if (e.target.value) {
                          await trigger('impersonateUsername');
                        }
                      },
                    })}
                  />
                </FormField>
              )}

              <FormField
                errorMessage={errors.password && 'Password is required'}
                label="Password"
              >
                <Input
                  type="password"
                  {...register('password', {
                    required: true,
                    onChange: () => clearErrors('password'),
                  })}
                />
              </FormField>

              <Button
                className="self-stretch"
                disabled={signingInWithSF || impersonatePending}
                loading={isSubmitting}
                type="submit"
                variant="solid"
              >
                {supportMode ? 'Log in as user' : 'Log in'}
              </Button>
              <ButtonLink
                className="self-stretch"
                disabled={signingInWithSF || impersonatePending}
                type="button"
                variant="subtle"
                href={{
                  pathname: '/forgot-password',
                  query: { loginAttempts: String(loginAttempts.current) },
                }}
              >
                Forgot your password?
              </ButtonLink>
              {supportMode && (
                <>
                  <div className="flex w-full items-center gap-2">
                    <div className="h-px grow bg-cool-gray-200" />
                    or authenticate with
                    <div className="h-px grow bg-cool-gray-200" />
                  </div>
                  <Button
                    className="w-full lg:mb-[-16px]"
                    disabled={isSubmitting}
                    loading={impersonatePending || signingInWithSF}
                    onClick={signInWithSalesforce}
                    type="button"
                    variant="outline"
                  >
                    Salesforce
                  </Button>
                </>
              )}
            </div>
          </form>
          <hr className="mt-6 text-cool-gray-200 lg:mt-10" />
          <div className="mt-6 flex justify-center text-island-dark lg:mt-10">
            Not an Evolve Owner yet?&nbsp;
            <Link
              href="https://evolve.com/vacation-rental-management?utm_source=owner_account&utm_medium=login_screen"
              onClick={() => {
                trackEvent('Learn More Clicked');
              }}
            >
              Learn more
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
