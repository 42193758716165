import {
  useCallback,
  useImperativeHandle,
  useRef,
  type ChangeEventHandler,
  type TextareaHTMLAttributes,
  type Ref,
} from 'react';

import { BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

import { EditableStyle } from '../shared/EditableStyle';

type TextAreaRef = HTMLTextAreaElement | null;

export type TextAreaProps = BaseProps &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    ref?: Ref<HTMLTextAreaElement>;
    onValueChange?: (value: string) => unknown;
  };

export const TextArea = ({
  ref,
  className,
  placeholder,
  invertTheme,
  onChange,
  onValueChange,
  ...props
}: TextAreaProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle<TextAreaRef, TextAreaRef>(ref, () => inputRef.current);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      onChange?.(e);
      onValueChange?.(e.target.value);
    },
    [onChange, onValueChange]
  );

  return (
    <div
      className={cn('relative', {
        'theme-invert': invertTheme,
        'text-disabled': props.disabled,
        'text-default dark:text-white': !props.disabled,
      })}
    >
      <EditableStyle
        className={cn('h-auto min-w-[280px] px-4 py-2.5', className)}
      >
        <textarea
          {...props}
          onChange={handleChange}
          placeholder={placeholder ?? '\u200b'}
          ref={inputRef}
        />
      </EditableStyle>
    </div>
  );
};
