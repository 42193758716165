import { setContext } from '@apollo/client/link/context';
import { HttpLink } from '@apollo/client/link/http';
import { type Session } from 'next-auth';
import { type Cookies } from 'next-client-cookies';

import { isServerSide } from 'utils/nextjs';

const clientName = `owner-web-${isServerSide() ? 'server' : 'client'}` as const;

interface LinkOptions {
  getCookies: () => Promise<Pick<Cookies, 'get'>>;
  getSession: () => Promise<Session | null> | Session | null;
}

/**
 * Get an isomorphic Apollo Client link.
 *
 * @returns An authenticated Apollo Client HTTP link.
 */
export const getLink = ({ getCookies, getSession }: LinkOptions) => {
  const uri = process.env.NEXT_PUBLIC_EVOLVE_GRAPH_API_URL;
  if (!uri) {
    throw new Error('NEXT_PUBLIC_EVOLVE_GRAPH_API_URL is not defined');
  }

  const httpLink = new HttpLink({
    uri,
    // does not work on pages with `export const dynamic = "force-static"`
    fetchOptions: { cache: 'no-store' },
  });

  const authLink = setContext(async (_, { headers }) => {
    const [cookies, session] = await Promise.all([getCookies(), getSession()]);

    const token = session?.user.accessToken;
    const hubspotutk = cookies.get('hubspotutk');

    return {
      headers: {
        ...(headers as Record<string, string>),
        ...(token ? { authorization: `Bearer ${token}` } : {}),
        ...(hubspotutk ? { 'x-hubspotutk': hubspotutk } : {}),
        'x-graphql-client-name': clientName,
      },
    };
  });

  return authLink.concat(httpLink);
};
