import { Slot } from '@radix-ui/react-slot';
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';
import { use, type AnchorHTMLAttributes, type Ref } from 'react';

import { MobileContext } from 'components/contexts/MobileContext';
import { BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

export type LinkProps = Omit<
  BaseProps & AnchorHTMLAttributes<HTMLElement>,
  'href'
> &
  NextLinkProps & {
    ref?: Ref<HTMLAnchorElement>;
    cta?: boolean;
    asChild?: boolean;
  };

export const Link = ({
  ref,
  className,
  cta,
  asChild = false,
  href,
  invertTheme,
  ...props
}: LinkProps) => {
  // This is for handling links that are embbedded in mobile apps. Not mobile devices on browser.
  const isMobile = use(MobileContext);
  const calculatedHref = isMobile ? `/mobile${href}` : href;

  const Component = asChild ? Slot : NextLink;

  return (
    <Component
      {...props}
      data-cta={cta || undefined}
      href={calculatedHref}
      ref={ref}
      className={cn(
        { 'theme-invert': invertTheme },
        'text-base font-bold underline hover:cursor-pointer hover:no-underline active:no-underline',
        'text-default',
        'visited:text-mountain-900 hover:text-mountain-600 active:text-mountain-400 data-cta:text-mountain',
        'dark:text-white dark:visited:text-disabled-light',
        'dark:hover:text-mountain-100 dark:active:text-white data-cta:dark:text-mountain-300',
        'focus-visible:ring-2 focus-visible:ring-focus focus-visible:ring-offset-2',
        'disabled:text-disabled-light disabled:no-underline disabled:dark:text-disabled-dark',
        className
      )}
    />
  );
};
