import {
  useImperativeHandle,
  useRef,
  type Ref,
  type InputHTMLAttributes,
  type MouseEventHandler,
} from 'react';
import {
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';

import { Button } from 'components/Buttons';
import { ChevronDownIcon, CloseIcon } from 'components/Icons';
import { Menu, MenuItem } from 'components/Menu';
import { BaseProps } from 'components/types';
import { setNativeValue } from 'utils/react';
import { cn } from 'utils/tailwind';

import { IconContainer } from '../shared';
import { EditableStyle } from '../shared/EditableStyle';

type InputRef = HTMLInputElement | null;

export type PhoneInputProps = BaseProps &
  Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'value' | 'onChange' | 'defaultValue'
  > & {
    ref?: Ref<HTMLInputElement>;
    value?: string;
    formattedValue?: string;
    disableInputOnly?: boolean;
    onChange?: (phone: string) => void;
  };

export const PhoneInput = ({
  ref,
  className,
  invertTheme,
  onChange,
  value,
  disableInputOnly,
  disabled,
  formattedValue,
  ...props
}: PhoneInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (inputRef.current) {
      e.preventDefault();
      setNativeValue(inputRef.current, '');
      inputRef.current.dispatchEvent(
        new InputEvent('change', { bubbles: true })
      );
    }
  };

  const {
    inputValue,
    handlePhoneValueChange,
    inputRef: phoneInputRef,
    country,
    setCountry,
  } = usePhoneInput({
    disableDialCodeAndPrefix: true,
    inputRef: inputRef,
    defaultCountry: 'us',
    value,
    countries: defaultCountries,
    onChange: (data) => {
      onChange?.(data.phone);
    },
  });

  useImperativeHandle<InputRef, InputRef>(ref, () => phoneInputRef.current);

  const disabledInput = disabled || disableInputOnly;
  const disabledCountrySelect = disabled;

  return (
    <div
      className={cn('relative', className, {
        'theme-invert': invertTheme,
        'text-default dark:text-white': !disabledInput,
      })}
    >
      <EditableStyle
        className={cn('pl-[6.25rem]', {
          'disabled:text-default': disabledInput && !disabledCountrySelect,
          'disabled:text-disabled': disabledInput && disabledCountrySelect,
        })}
      >
        <input
          disabled={disabledInput}
          onChange={handlePhoneValueChange}
          ref={phoneInputRef}
          value={formattedValue || inputValue}
          placeholder={
            props.placeholder ??
            (typeof country.format === 'string'
              ? country.format.replaceAll('.', 'X')
              : (country.format?.default.replaceAll('.', 'X') ??
                '(XXX) XXX-XXXX'))
          }
          {...props}
        />
      </EditableStyle>
      <Menu
        portal
        className="max-h-96 overflow-y-scroll"
        trigger={
          <Button
            trailingIcon={<ChevronDownIcon />}
            variant="text"
            className={cn(
              'absolute inset-y-px left-px flex w-20 items-center justify-center rounded-l-[0.175rem] border-y-0 border-l-0 border-r border-r-island-dark p-2 text-default hover:enabled:text-default peer-data-touched:peer-required:peer-invalid:peer-not-focus:border-r-error  peer-invalid:[&:not(:peer-focus):not(:peer-required)]:border-r-error',
              {
                'bg-white hover:bg-warm-gray-100': !disabledCountrySelect,
                'pointer-events-none': disabledCountrySelect,
              }
            )}
          >
            +{country.dialCode}
          </Button>
        }
      >
        {defaultCountries.map((c) => {
          const country = parseCountry(c);
          return (
            <MenuItem
              key={country.iso2}
              onSelect={() => setCountry(country.iso2)}
            >
              <span className="flex items-center">
                <p className="mr-2">{country.name}</p>
                <p>+{country.dialCode}</p>
              </span>
            </MenuItem>
          );
        })}
      </Menu>

      <IconContainer
        alignment="right"
        className="text-current peer-placeholder-shown:hidden peer-error:hidden peer-not-focus:hidden"
      >
        <button
          aria-label="Clear"
          className="-mr-1 appearance-none pr-1"
          onClick={handleClear}
          onMouseDown={(e) => e.preventDefault()}
          type="button"
        >
          <CloseIcon size="0.75rem" />
        </button>
      </IconContainer>
    </div>
  );
};
