import React, { ReactElement } from 'react';

import {
  BaseButtonProps,
  BaseButton,
} from 'components/Buttons/shared/BaseButton';
import { LoadingIcon } from 'components/Icons';
import { cn } from 'utils/tailwind';

export type ButtonProps = BaseButtonProps & {
  leadingIcon?: ReactElement;
  trailingIcon?: ReactElement;
  loading?: boolean;
  highlight?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export const Button = ({
  ref,
  leadingIcon,
  trailingIcon,
  children,
  size,
  className,
  loading: loadingProp,
  highlight,
  ...buttonProps
}: ButtonProps) => {
  const variant = buttonProps.variant;
  const theme = buttonProps.buttonTheme;

  const hasIcon = Boolean(leadingIcon) || Boolean(trailingIcon);

  // Loading is treated as true only if button is loading and is not disabled
  const loading = loadingProp && !buttonProps.disabled;

  return (
    <BaseButton
      data-loading={loading}
      ref={ref}
      className={cn(
        'gap-2',
        {
          'text-sm': size !== 'small',
          'text-xs': size === 'small',
          'py-3':
            variant === 'solid' ||
            variant === 'outline' ||
            variant === 'outline-new' ||
            variant === 'subtle',
          'py-2':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            size === 'medium',
          'py-[6px]':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            size === 'small',
          'px-4':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            (!hasIcon || (Boolean(leadingIcon) && Boolean(trailingIcon))),
          'px-3':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            size === 'medium',
          'px-[10px]':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            size === 'small',
          'pr-5 pl-4':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            Boolean(leadingIcon) &&
            !trailingIcon,
          'pr-4 pl-5':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            Boolean(trailingIcon) &&
            !leadingIcon,
          'bg-mountain-100': highlight,
        },
        className
      )}
      {...buttonProps}
    >
      {leadingIcon && (
        <span
          aria-hidden={true}
          // Hide icons when loading
          className={cn({ invisible: loading })}
        >
          {React.cloneElement(leadingIcon, {
            // @ts-expect-error Unknown prop
            size: size === 'small' ? 12 : 14,
          })}
        </span>
      )}

      <span
        // Hide children when loading
        className={cn(
          { invisible: loading },
          'flex items-center justify-center'
        )}
      >
        {children}
      </span>

      {trailingIcon && (
        <span
          aria-hidden={true}
          // Hide icons when loading
          className={cn({ invisible: loading })}
        >
          {React.cloneElement(trailingIcon, {
            // @ts-expect-error Unknown prop
            size: size === 'small' ? 12 : 14,
          })}
        </span>
      )}

      {/* Show loading spinner when loading */}
      {loading && (
        <span className="absolute">
          <LoadingIcon
            className={cn({
              'stroke-white dark:stroke-island-dark': variant === 'solid',
              'stroke-mountain-500':
                variant === 'text' ||
                variant === 'text-island' ||
                (variant === 'outline' && theme === 'default') ||
                (variant === 'subtle' && theme === 'default'),
              'stroke-destructive-light':
                (variant === 'outline' && theme === 'destructive') ||
                (variant === 'subtle' && theme === 'destructive'),
              'stroke-utility-light':
                (variant === 'outline' && theme === 'utility') ||
                (variant === 'subtle' && theme === 'utility'),
              'stroke-island-700': variant === 'outline-new',
            })}
            size={
              variant === 'subtle' ||
              variant === 'text' ||
              variant === 'text-island'
                ? 18
                : 24
            }
          />
        </span>
      )}
    </BaseButton>
  );
};
