'use client';

import Script from 'next/script';

import type { FacebookPixelProperties } from 'types/global';

/**
 * Track Facebook Pixel events
 *
 * @link https://developers.facebook.com/docs/facebook-pixel/advanced/
 */
export const trackPixelEvent = (
  name: string,
  options: FacebookPixelProperties = {}
) => window?.fbq?.('track', name, options);

interface Props {
  pixelId?: string;
  pixelScriptUrl?: string;
  nonce?: string;
}

export const FacebookPixel = ({
  pixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  pixelScriptUrl = 'https://connect.facebook.net/en_US/fbevents.js',
  nonce,
}: Props) => {
  if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
    return null;
  }

  return (
    <>
      <Script
        data-pixel-id={pixelId}
        id={`fb-pixel-${pixelId}`}
        nonce={nonce}
        strategy="afterInteractive"
      >
        {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document, 'script', '${pixelScriptUrl}');
fbq('init', '${pixelId}');
fbq('track', 'PageView');`}
      </Script>
      <noscript>
        {/* eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element */}
        <img
          height="1"
          src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
          style={{ display: 'none' }}
          width="1"
        />
      </noscript>
    </>
  );
};
