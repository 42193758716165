import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type Ref,
} from 'react';

import { type BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

export type ButtonVariant =
  | 'solid'
  | 'outline'
  | 'outline-new'
  | 'subtle'
  | 'text'
  | 'text-island';

export type ButtonTheme = 'default' | 'destructive' | 'utility';

export type BaseButtonProps = {
  ref?: Ref<HTMLButtonElement | HTMLAnchorElement>;
  /**
   * The type of button to render.
   *
   * **Primary** = `'solid'`
   *
   * **Secondary** = `'outline'`
   *
   * **Tertiary** = `'subtle'`
   * @default outline
   */
  variant?: ButtonVariant;
  /**
   * An href to render a button as a link.
   * Providing a value for this prop will automatically change the underlying component from a `button` to an `a`.
   * @default undefined
   */
  href?: string;
  /**
   * The theme of the button
   */
  buttonTheme?: ButtonTheme;
} & BaseProps &
  ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export const BaseButton = ({
  ref,
  variant = 'solid',
  buttonTheme = 'default',
  invertTheme,
  className,
  ...props
}: BaseButtonProps) => {
  const Component = props.href ? 'a' : 'button';

  return (
    <Component
      ref={ref as never}
      className={cn(
        'flex appearance-none items-center justify-center border-2 border-solid font-heading text-sm font-bold leading-5 outline-none transition-colors',
        'focus-visible:ring-2 focus-visible:ring-focus focus-visible:ring-offset-2',
        'disabled:pointer-events-none data-loading:pointer-events-none',
        {
          'theme-invert': invertTheme,
          // Primary buttons
          'rounded-full bg-cta text-white hover:bg-mountain-600 active:bg-mountain-400 border-transparent disabled:bg-disabled-light disabled:text-white data-loading:bg-mountain-400 dark:bg-mountain-300 dark:text-island-dark hover:dark:bg-mountain-400 active:dark:bg-mountain-light dark:disabled:bg-island-700':
            variant === 'solid' && buttonTheme === 'default',
          'rounded-full bg-destructive text-white hover:bg-destructive-dark active:bg-destructive-light border-transparent disabled:bg-disabled-light disabled:text-white data-loading:bg-destructive-light dark:bg-[#EFAD9C] dark:text-island-dark hover:dark:bg-destructive-light active:dark:bg-[#F3C1B5] dark:disabled:bg-island-700':
            variant === 'solid' && buttonTheme === 'destructive',
          'rounded-full bg-utility text-white hover:bg-utility-dark active:bg-utility-light border-transparent disabled:bg-disabled-light disabled:text-white data-loading:bg-utility-light dark:text-island-dark dark:bg-island-light hover:dark:bg-island-400 active:dark:bg-island-200 dark:disabled:bg-island-700':
            variant === 'solid' && buttonTheme === 'utility',
          // Secondary buttons
          'rounded-full bg-transparent border-cta text-cta hover:bg-mountain-100 hover:text-mountain-700 hover:border-mountain-700 focus-visible:bg-transparent focus-visible:border-cta focus-visible:text-cta active:bg-transparent active:border-mountain-400 active:text-mountain-400 data-loading:bg-transparent data-loading:border-mountain-400 data-loading:text-mountain-400 disabled:bg-transparent disabled:border-disabled-light disabled:text-disabled-light':
            variant === 'outline' && buttonTheme === 'default',
          'rounded-full bg-transparent border-destructive text-destructive hover:bg-[#F7D6CE] hover:text-destructive-dark hover:border-destructive-dark focus-visible:bg-transparent focus-visible:border-destructive focus-visible:text-destructive active:bg-transparent active:border-destructive-light active:text-destructive data-loading:bg-transparent data-loading:border-destructive-light data-loading:text-destructive-light disabled:bg-transparent disabled:border-disabled-light disabled:text-disabled-light':
            variant === 'outline' && buttonTheme === 'destructive',
          'rounded-full bg-transparent border-utility text-utility hover:text-island-dark hover:border-island-dark focus-visible:bg-transparent focus-visible:border-utility focus-visible:text-utility active:bg-transparent active:border-utility-light active:text-utility-light data-loading:bg-transparent data-loading:border-utility-light data-loading:text-utility-light disabled:bg-transparent disabled:border-disabled-light disabled:text-disabled-light':
            variant === 'outline' && buttonTheme === 'utility',
          'rounded-full bg-transparent border-island-700 text-island-700 hover:border-island-600 hover:bg-island-100 hover:text-island-700 active:border-island-500 active:bg-island-100 active:text-island-700 disabled:bg-transparent disabled:border-disabled-light disabled:text-disabled-light data-loading:bg-island-100':
            variant === 'outline-new',
          // Tertiary buttons
          'rounded-full bg-transparent border-transparent text-cta focus-visible:ring-offset-0 hover:bg-mountain-100 hover:text-mountain-700 active:bg-transparent active:text-mountain-400 data-loading:bg-mountain-100 disabled:bg-transparent disabled:text-disabled-light dark:text-mountain-100 dark:hover:bg-island-700 dark:active:bg-island-700 dark:data-loading:bg-island-700 dark:active:text-white dark:disabled:text-disabled-dark':
            variant === 'subtle' && buttonTheme === 'default',
          'rounded-full bg-transparent border-transparent text-destructive focus-visible:ring-offset-0 focus-visible:border-island-light focus-visible:text-destructive hover:text-destructive-dark hover:bg-[#F7D6CE] active:bg-transparent active:text-destructive-light data-loading:text-destructive-light disabled:bg-transparent disabled:text-disabled-light':
            variant === 'subtle' && buttonTheme === 'destructive',
          'rounded-full bg-transparent border-transparent text-utility focus-visible:ring-offset-0 focus-visible:border-island-light focus-visible:text-utility hover:text-utility-dark hover:bg-island-100 active:bg-transparent active:text-utility-light data-loading:text-utility-light disabled:bg-transparent disabled:text-disabled-light':
            variant === 'subtle' && buttonTheme === 'utility',

          'border-transparent rounded-none text-cta hover:text-mountain-600 active:text-mountain-400 disabled:text-disabled-light dark:text-mountain-100 dark:hover:text-mountain-400 dark:active:text-white dark:disabled:text-disabled-dark':
            variant === 'text',
          'border-transparent rounded-none text-island-700 hover:text-island-900 active:text-island-900 disabled:text-disabled-light dark:text-mountain-100 dark:hover:text-mountain-400 dark:active:text-white dark:disabled:text-disabled-dark':
            variant === 'text-island',
        },
        className
      )}
      {...props}
    />
  );
};
