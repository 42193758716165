/**
 * Attaches the accessToken to the request and provides some additional session-checking behavior:
 *
 * This function handles 401 statuses by fetching a new session, sharing the access token with the rest of the app through
 * a storage event (next-auth listens for these), and then retrying the request with the new session. This encapsulates
 * the refresh logic to a single location (this function), so consumers know that when they recieve a 401, there's nothing
 * else to be done and can handle the error.
 *
 * Previously, we attempted to refresh the session through react-query error handling. The issue we ran into was
 * the new access token was not used in subsequent requests due to the token being read from the `useSession` hook,
 * which doesn't update until the next render.
 *
 * If the refresh fails:
 * On the client, queryClient.ts will catch this and redirect the user to the signin page.
 * On the server, we'll redirect the user to a special signout page that will call the client-side next-auth logout method
 * and redirect to /login with the callback
 *
 * @param url The URl for the request
 * @param accessToken The user's current access token
 * @param method The HTTP request method, defaults to GET
 * @param options Any additional options to be included in the fetch request
 * @param responseHandler What to do with the fetch response
 * @returns Whatever the response handler returns
 */
import { redirect } from 'next/navigation';
import { getCsrfToken } from 'next-auth/react';

import { isClientSide, isServerSide } from 'utils/nextjs';

let refreshRequest: Promise<string> | null = null;

const RETRIES = 1;

export async function refreshSession({
  accessToken,
  supportUsername,
}: {
  accessToken?: string;
  supportUsername?: string;
} = {}) {
  let newAccessToken: string | null = null;

  if (isClientSide()) {
    const csrfToken = await getCsrfToken();
    const sessionResponse = await fetch('/api/auth/session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        csrfToken,
        data: {
          forceRefresh: true,
          supportUsername,
        },
      }),
    });

    const accessTokenData = (await sessionResponse.json()) as
      | {
          user: {
            accessToken: string;
          };
        }
      | {
          error: string;
        };

    if ('error' in accessTokenData) {
      throw new Error(accessTokenData.error);
    }

    newAccessToken = accessTokenData.user.accessToken;

    // Manually trigger storage event so that next-auth triggers a session refetch within the provider
    // Without this, `getSession` doesn't actually propagate the new session into the provider
    // even though the docs say:
    // "If you need to, you can trigger an update of the session object across all
    // tabs/windows by calling getSession() from a client side function"
    const e = new StorageEvent('storage', {
      storageArea: window.localStorage,
      key: 'nextauth.message',
      oldValue: '{}',
      newValue: JSON.stringify({
        event: 'session',
        data: { trigger: 'getSession' },
        timestamp: Math.floor(Date.now() / 1000),
      }),
      url: window.location.href,
    });
    window.dispatchEvent(e);
  } else {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_EXPERIENCE_API_URL}/salesforce/refresh`,
      {
        method: 'PATCH',
        headers: {
          Authorization: accessToken || '',
        },
        body: JSON.stringify({
          supportUsername,
        }),
      }
    );

    const refreshedTokenData = (await res.json()) as {
      accessToken: string;
    };

    newAccessToken = refreshedTokenData.accessToken;
  }

  return newAccessToken;
}

export async function fetchWithAuth<T = unknown>(
  url: string,
  accessToken?: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
  options?: RequestInit,
  responseHandler: (res: Response) => Promise<T> = async (res) =>
    res.json() as Promise<T>,
  currentRoute: string | null = null,
  currentCount = 0 // Used internally to recursively call this function until max retries are reached
): Promise<{ data: T; headers: Headers }> {
  const res = await fetch(url, {
    ...options,
    method,
    headers: { ...options?.headers, Authorization: accessToken || '' },
  });

  // We good? Return the response
  if (res.ok) {
    const data = await responseHandler(res);
    return { data, headers: res.headers };
  }

  // Error handling
  if (res.status === 401) {
    if (currentCount < RETRIES) {
      if (isClientSide()) {
        // On the browser, reuse in-flight request to refresh the session so we don't
        // send a bunch of parallel requests
        refreshRequest ??= refreshSession({ accessToken });
      } else {
        // On the server, create a new refresh request every time so we don't leak user sessions
        refreshRequest = refreshSession({ accessToken });
      }

      try {
        const newAccessToken = await refreshRequest;

        refreshRequest = null;

        return fetchWithAuth(
          url,
          newAccessToken,
          method,
          options,
          responseHandler,
          currentRoute,
          currentCount + 1
        );
      } catch (error) {
        // If the refresh fails, we'll throw an error
        refreshRequest = null;
      }
    }

    if (isServerSide()) {
      let callbackRoute = currentRoute;

      if (!callbackRoute) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { headers } = await import('next/headers');
        const allHeaders = await headers();

        callbackRoute = allHeaders.get('x-current-path');
      }

      // See /unauthenticate/page.tsx for information about why we need an in-between page
      redirect(
        `/unauthorized?callbackUrl=${encodeURIComponent(callbackRoute ?? '/')}`
      );
    }

    throw { ...new Error('Unauthorized'), response: res };
  }
  throw { ...new Error('Unknown HTTP Error'), response: res };
}
