import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuTriggerProps,
  DropdownMenuContentProps,
  DropdownMenuPortalProps,
  DropdownMenuProps,
  DropdownMenuItem,
  DropdownMenuItemProps,
} from '@radix-ui/react-dropdown-menu';

import { Button } from 'components/Buttons';
import { cn } from 'utils/tailwind';

import type { ReactNode } from 'react';

export type MenuProps = DropdownMenuContentProps & {
  trigger?: ReactNode;
  container?: DropdownMenuPortalProps['container'];
  rootProps?: DropdownMenuProps;
  portal?: boolean;
};
export const Menu = ({
  trigger,
  className,
  container = document.body,
  rootProps,
  portal,
  ...props
}: MenuProps) => (
  <DropdownMenu {...rootProps}>
    <DropdownMenuTrigger asChild>{trigger ?? <Button />}</DropdownMenuTrigger>
    {portal ? (
      <DropdownMenuPortal container={container}>
        <DropdownMenuContent
          side="bottom"
          sideOffset={10}
          className={cn(
            'z-50 rounded-lg bg-white py-2 drop-shadow dark:bg-island-dark dark:drop-shadow-dark',
            className
          )}
          {...props}
        />
      </DropdownMenuPortal>
    ) : (
      <DropdownMenuContent
        side="bottom"
        sideOffset={10}
        className={cn(
          'z-50 rounded-lg bg-white py-2 drop-shadow dark:bg-island-dark dark:drop-shadow-dark',
          className
        )}
        {...props}
      />
    )}
  </DropdownMenu>
);

export type MenuTriggerProps = Omit<DropdownMenuTriggerProps, 'asChild'>;

export const MenuDivider = () => (
  <div className="my-2 w-full border-b border-accent-gray-200 dark:bg-cool-gray-200" />
);

export type MenuItemProps = DropdownMenuItemProps;

export const MenuItem = ({ className, disabled, ...props }: MenuItemProps) => {
  return (
    <DropdownMenuItem
      className={cn(
        'cursor-pointer px-6 py-2 font-heading font-medium text-default outline-none dark:text-white',
        'hover:bg-island-100 dark:hover:bg-island-700',
        'focus-visible:ring-2 focus-visible:ring-focus focus-visible:ring-offset-2',
        {
          'hover:cursor-default pointer-events-none text-disabled-light':
            disabled,
        },
        className
      )}
      {...props}
    />
  );
};
